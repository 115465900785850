import "./sass/main.scss";

//Routes
import RoutesManager from "./routes/RoutesMananger";

function App() {
  return (
    <div className="App">
      <RoutesManager />
    </div>
  );
}

export default App;
