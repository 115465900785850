import axios from "axios";

export const getAllWeeklyCampaigns = async () => {
  return axios
    .get("https://strapi-production-1c8f.up.railway.app/api/weekly-campaigns")
    .then((res) => res.data.data[0].attributes);
};

export const getAllOneShots = async () => {
  return axios
    .get("https://strapi-production-1c8f.up.railway.app/api/one-shots")
    .then((res) => res.data.data[0].attributes);
};
