import React from "react";
import Socialicons from "../SocialIcons/Socialicons";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__container">
        {/* Left */}
        <div className="footer__left">
          <div className="footer__logoContainer">
            <h4>Adventure Foundry</h4>
          </div>
          <div>
            <p>admin@adventurefoundrygames.com</p>
          </div>
        </div>
        {/* Right */}
        <div className="footer__right">
          <Socialicons />
        </div>
      </div>
    </div>
  );
};

export default Footer;
