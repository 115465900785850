import React from "react";

const Modal = ({ visible, handleModalClose, campaign }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal__container">
          <div
            className="modal__imgContainer"
            style={{
              backgroundImage: `url(https://drive.google.com/uc?export=view&id=${campaign.ImageId})`,
              objectFit: "cover",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>

          <div className="modal__right">
            <div className="modal__titleContainer">
              <div>
                <img
                  src={require("../../assets/iconsSVG/calendar.png")}
                  alt=""
                />
                {campaign.Time}
              </div>
              <p>{campaign.Name}</p>
            </div>

            <div className="modal__descriptionContainer">
              <p>{campaign.Description}</p>
            </div>

            <div className="modal__btnContainer">
              <button className="btnMain" onClick={handleModalClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
