import React from "react";
import { CircleLoader } from "react-spinners";

const Spinner = ({ color, size, width, height }) => {
  return (
    <div
      style={{
        width: width || "100vw",
        height: height || "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000",
      }}
    >
      <CircleLoader
        color={color || "#ffae0c"}
        loading
        size={size || 200}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Spinner;
