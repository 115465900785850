import { configureStore } from "@reduxjs/toolkit";

//Slices
import campaignsReducer from "./slices/campaignsSlice";

export const store = configureStore({
  reducer: {
    campaigns: campaignsReducer,
  },
});
