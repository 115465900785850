//concatString String Function
export const concatString = (string, length) => {
  return `${string.slice(0, length - 1).trim()}${
    string.length > length ? "..." : ""
  }`;
};

export const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth",
  });
};
