import React, { useEffect } from "react";

//Components
import CampaignCard from "../../Components/CampaignCard/CampaignCard";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setWeeklyCampaign,
  setOneShots,
} from "../../Redux/slices/campaignsSlice";
import axios from "axios";

const Campaigns = () => {
  const dispatch = useDispatch();

  const { weeklyCampaigns, oneShots } = useSelector((state) => state.campaigns);

  useEffect(() => {
    const getAllWeeklyCampaigns = async () => {
      return axios
        .get(
          "https://strapi-production-1c8f.up.railway.app/api/weekly-campaings"
        )
        .then((res) => {
          dispatch(setWeeklyCampaign(res.data.data));
        })
        .catch((err) => {
          console.log("Error fetching all weekly campaigns=====> ", err);
        });
    };

    const getAllOneShots = async () => {
      return axios
        .get("https://strapi-production-1c8f.up.railway.app/api/one-shots")
        .then((res) => {
          dispatch(setOneShots(res.data.data));
        })
        .catch((err) => {
          console.log("Error fetching all one shots=====> ", err);
        });
    };

    getAllWeeklyCampaigns();
    getAllOneShots();
  }, [dispatch]);

  return (
    <div className="campaigns section">
      <div className="campaigns__container">
        <div className="campaigns__weeklyContainer" id="weekly">
          <div className="campaigns__titleContainer">
            <h2>Weekly Campaigns</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.{" "}
            </p>
          </div>
          <div className="campaigns__cardsContainer">
            {weeklyCampaigns.map((campaign) => (
              <CampaignCard campaign={campaign.attributes} key={campaign.id} />
            ))}
          </div>
        </div>
        <div className="campaigns__onShotsContainer" id="oneShots">
          <div className="campaigns__titleContainer">
            <h2>One Shots</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.{" "}
            </p>
          </div>
          <div className="campaigns__cardsContainer">
            {oneShots.map((campaign) => (
              <CampaignCard campaign={campaign.attributes} key={campaign.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
