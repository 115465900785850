import React, { useState } from "react";
import { concatString } from "../../utils/UtilFunctions";

//Components
import CampaignModal from "../CampaingModal/CampaignModal";

const CampaignCard = ({ campaign }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(!isModalOpen);

  return (
    <div className="campaignCard">
      {isModalOpen && (
        <CampaignModal
          campaign={campaign}
          visible={isModalOpen}
          handleModalClose={handleModalOpen}
        />
      )}

      <div onClick={handleModalOpen} className="campaignCard__click">
        {/*  */}
        <div className="campaignCard__imgContainer">
          <img
            className="campaignCard__imgContainer--img"
            src={`https://drive.google.com/uc?export=view&id=${campaign.ImageId}`}
            alt={campaign.Name}
          />
          <div className="campaignCard__imgContainer--seatsContainer">
            <p className="campaignCard__imgContainer--seats">
              {campaign.SeatsLeft} {campaign.SeatsLeft > 1 ? "Seats" : "Seat"}{" "}
              left
            </p>
          </div>
        </div>
        {/*  */}
        <div className="campaignCard__container">
          <div className="campaignCard__titleContainer">
            <div>
              <img src={require("../../assets/iconsSVG/calendar.png")} alt="" />
              {campaign.Time}
            </div>
            <p>{campaign.Name}</p>
            <p>{campaign.Price}</p>
          </div>

          <div className="campaignCard__descriptionContainer">
            <p>{concatString(campaign.Description, 100)}</p>
            <span>Click for more...</span>
          </div>
          <div>
            <p>
              Total seats: <span>{campaign.TotalSeats}</span>
            </p>
          </div>
        </div>
      </div>
      {/*  */}
      {/* <div className="campaignCard__btnContainer">
        <button className="btnMain">Learn More</button>
      </div> */}
    </div>
  );
};

export default CampaignCard;
