import { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

//Assets

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navigation">
      <input
        type="checkbox"
        className="navigation__checkbox"
        id="navi-toggle"
        value={isOpen}
        onChange={handleOpen}
        checked={isOpen}
      />

      <label className="navigation__button" htmlFor="navi-toggle">
        <span className="navigation__icon"></span>
      </label>

      <div className="navigation__background"></div>

      <nav className="navigation__nav">
        <div className="navigation__logoContainer">
          {/* <img src={TempLogo} alt="Company logo" /> */}
          <p>Adventure Foundry</p>
        </div>
        <ul className="navigation__list">
          <>
            {/* {userInfo && userInfo.isAdmin ? (
                <li className="navigation__item">
                  <Link to="/admin" className="navigation__link" onClick={handleOpen}>
                    Admin Dashboard
                  </Link>
                </li>
              ) : null} */}
            {/* <li className="navigation__item">
                <Link to="/profile" className="navigation__link" onClick={handleOpen}>
                  {userInfo.name.split(" ")[0]}'s Profile
                </Link>
              </li> */}
            <li className="navigation__item">
              <Link to="/" className="navigation__link" onClick={handleOpen}>
                Home
              </Link>
            </li>{" "}
            <li className="navigation__item">
              <HashLink
                to="/#services"
                smooth
                className="navigation__link"
                onClick={handleOpen}
              >
                Services
              </HashLink>
            </li>
            <li className="navigation__item">
              <Link
                to="/#campaigns"
                className="navigation__link"
                onClick={handleOpen}
              >
                Campaigns
              </Link>
            </li>
            <li className="navigation__item">
              <HashLink
                smooth
                to="/#dms"
                className="navigation__link"
                onClick={handleOpen}
              >
                DM's
              </HashLink>
            </li>
            <li className="navigation__item">
              <HashLink
                smooth
                to="/#contact"
                className="navigation__link"
                onClick={handleOpen}
              >
                Contact Us
              </HashLink>
            </li>
            <li className="navigation__item">
              <Link
                smooth
                to="/newplayer"
                className="navigation__link"
                onClick={handleOpen}
              >
                Become a Player
              </Link>
            </li>
          </>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
