import React, { lazy, Suspense } from "react";

//Router
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Spinner
import Spinner from "../Components/Spinner/Spinner";

//HOC
import ScrollToTopFix from "../HOC/ScrollToTopFix";

//Components
import NavBar from "../Components/NavBar/NavBar";
import Footer from "../Components/Footer/Footer";
import Campaigns from "../Pages/Campaigns/Campaigns";

//LazyLoading
const Home = lazy(() => import("../Pages/Home/Home"));
const NewPlayerForm = lazy(() =>
  import("../Pages/NewPlayerForm/NewPlayerForm")
);

const RoutesManager = () => {
  return (
    <BrowserRouter>
      <ScrollToTopFix>
        <Suspense fallback={<Spinner />}>
          <NavBar />
          <Routes>
            <Route exact path="/newplayer" element={<NewPlayerForm />}></Route>
            <Route exact path="/campaigns" element={<Campaigns />}></Route>

            <Route exact path="/" element={<Home />}></Route>
          </Routes>
          <Footer />
        </Suspense>
      </ScrollToTopFix>
    </BrowserRouter>
  );
};

export default RoutesManager;
