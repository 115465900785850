import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllWeeklyCampaigns,
  getAllOneShots,
} from "../../api/campaigns/campaignsApi";

const initialState = {
  weeklyCampaigns: [],
  oneShots: [],
};

export const fetchWeeklyCampaigns = createAsyncThunk(
  "campaigns/weekly",
  getAllWeeklyCampaigns
);
export const fetchOneShots = createAsyncThunk(
  "campaigns/oneShots",
  getAllOneShots
);

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setWeeklyCampaign: (state, action) => {
      state.weeklyCampaigns = action.payload;
    },
    setOneShots: (state, action) => {
      state.oneShots = action.payload;
    },
  },
});

export const { setWeeklyCampaign, setOneShots } = campaignsSlice.actions;

export default campaignsSlice.reducer;
