import React, { useEffect, useState } from "react";

//React Router
import { Link, useHref } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Menu from "./Menu";

//Assets
import logo from "../../assets/icon/icon1.png";
import { scrollWithOffset } from "../../utils/UtilFunctions";

const NavBar = () => {
  const [isCampaignPage, setIsCampaignPage] = useState(false);

  const href = useHref();

  useEffect(() => {
    setIsCampaignPage(href.includes("/campaigns"));
  }, [href]);

  return (
    <nav className="navBar">
      <div className="navBar__container">
        <div className="navBar__left">
          <Link to="/">
            <img src={logo} className="navBar__logo" alt="" />
          </Link>
        </div>

        <div className="NavBar__menu">
          <Menu />
        </div>

        <div className="navBar__right">
          <ul className="navBar__items">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <HashLink
                smooth
                to="/#services"
                scroll={(el) => scrollWithOffset(el, 50)}
              >
                Services
              </HashLink>
            </li>
            <li>
              <Link smooth to="/campaigns">
                Campaigns
              </Link>
            </li>
            <li>
              <HashLink
                smooth
                to="/#dms"
                scroll={(el) => scrollWithOffset(el, 50)}
              >
                DM's
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/#contact"
                scroll={(el) => scrollWithOffset(el, 50)}
              >
                Contact Us
              </HashLink>
            </li>
            <li>
              <Link
                smooth
                to="/newplayer"
                scroll={(el) => scrollWithOffset(el, 50)}
              >
                Become a Player
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {isCampaignPage && (
        <div className="navBar__subNavBar">
          <HashLink
            to="/campaigns/#weekly"
            smooth
            scroll={(el) => scrollWithOffset(el, 2)}
          >
            <p>Weekly Campaigns</p>
          </HashLink>
          <HashLink
            to="/campaigns/#oneShots"
            smooth
            scroll={(el) => scrollWithOffset(el, 2)}
          >
            <p>One Shots</p>
          </HashLink>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
